function isUndefined(val) {
  return val === undefined;
}
function isNull(val) {
  return val === null;
}
function isNullOrUndefined(val) {
  return isNull(val) || isUndefined(val);
}
const isNullOrUndefinedOrBlank = value => {
  if (typeof value === "string") {
    return isNullOrUndefined(value) || value.length === 0;
  }
  return isNullOrUndefined(value);
};

function getBackendApiURL() {
  const { hostname, protocol } = location;
  const apiHostname = hostname.replaceAll("viewer", "api");
  console.log("debug getBackendApiURL", `${protocol}//${apiHostname}`);
  return `${protocol}//${apiHostname}`;
}

class UserActivityHelper {
  static USER_INACTIVITY_TIMEOUT_SECONDS = 10;

  static LOG_ENTRY_SEND_INTERVAL_SECONDS = 60;

  static TICK_MILLIS = 1000;

  userActivityTimeout = undefined;

  publicIP = "";

  tick = undefined;

  activityLoggingInterval = undefined;

  state;

  currentLogEntry;

  initialized = false;

  user;

  initTracking() {
    return Promise.all([
      fetch(getBackendApiURL() + "/user/self", { credentials: "include" }).then(
        response => response.json()
      ),
      fetch("https://httpbin.org/ip")
        .then(response => response.json())
        .then(json => json.origin),
    ]).then(([user, ip]) => this.initialize(user, ip));
  }

  initialize(user, ip) {
    if (this.initialized) {
      return true;
    }
    console.log("initialized UserActivityHelper", ip, user);
    this.registerEventListeners();
    this.resetActivityTimer();
    this.state = "active";
    this.publicIP = ip;
    this.user = user;

    this.startNewLog();
    this.tick = setInterval(() => {
      const { currentLogEntry } = this;

      if (!isNullOrUndefined(currentLogEntry)) {
        if (this.state === "active") {
          currentLogEntry.activeTimeMillis += UserActivityHelper.TICK_MILLIS;
        } else {
          currentLogEntry.inactiveTimeMillis += UserActivityHelper.TICK_MILLIS;
        }
      }
    }, UserActivityHelper.TICK_MILLIS);
    this.setupLoggingInterval();

    setInterval(() => {
      if (
        !isNullOrUndefinedOrBlank(this.currentLogEntry?.fullUrl) &&
        location.pathname !== this.currentLogEntry?.fullUrl
      ) {
        this.handlePageChange();
      }
    }, 1000);

    this.initialized = true;
    return true;
  }

  setupLoggingInterval() {
    this.activityLoggingInterval = setInterval(() => {
      this.sendCurrentLog();
      this.startNewLog();
    }, UserActivityHelper.LOG_ENTRY_SEND_INTERVAL_SECONDS * 1000);
  }

  sendCurrentLog() {
    const env =
      getBackendApiURL() === "https://api.facilityscanner.com" ? "prod" : "dev";
    const { currentLogEntry } = this;
    const logToSend = {
      ...currentLogEntry,
      endTime: Date.now(),
      timestamp: new Date().toISOString(),
    };

    if (logToSend.activeTimeMillis > 0) {
      navigator.sendBeacon(
        `https://kpi.facilityscanner.com/fs-kpi-${env}-v1?APIKEY=847hYSULEea58Y72VI7WMWDb`,
        new Blob([JSON.stringify({ document: logToSend })], {
          type: "application/json",
        })
      );
    }
  }

  startNewLog() {
    const { pathname } = location;
    const path = pathname;
    const currentUser = this.user;
    this.currentLogEntry = {
      systemId: "internal.facilityscanner.com",
      startTime: Date.now(),
      fullUrl: location.pathname,
      route: path,
      ip: `${this.publicIP}` || "0.0.0.0",
      activeTimeMillis: 0,
      inactiveTimeMillis: 0,
      events: {},
      user: {
        id: currentUser?.id,
        className: currentUser?.className,
        email: currentUser?.email,
        name: (currentUser?.firstName ?? "") + " " + (currentUser?.name ?? ""),
      },
    };
  }

  handlePageChange() {
    clearInterval(this.activityLoggingInterval);
    this.sendCurrentLog();
    this.startNewLog();
    this.setupLoggingInterval();
  }

  handleEvent(type, ev) {
    console.log("debug handleEvent", type);
    this.resetActivityTimer();
    this.refreshActiveState();
    this.addEentToLog(type);
  }

  addEentToLog(type) {
    if (!this.currentLogEntry) {
      return;
    }
    if (!this.currentLogEntry.events[type]) {
      this.currentLogEntry.events[type] = 0;
    }
    this.currentLogEntry.events[type] += 1;
  }

  registerEventListeners() {
    [
      "keydown",
      "keyup",
      "click",
      "dblclick",
      "mouseup",
      "mousedown",
      "mousemove",
      "ontouchstart",
      "scroll",
    ].forEach(type => {
      window.addEventListener(
        type,
        ev => {
          this.handleEvent(type, ev);
        },
        true
      );
    });
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "hidden") {
        this.handlePageChange();
      }
    });

    window.addEventListener("blur", () => this.setInactive());
    window.addEventListener("focus", () => this.setActive());
  }

  setInactive() {
    console.log("debug inactive");
    this.state = "inactive";
  }

  refreshActiveState() {
    if (this.state !== "active") {
      this.setActive();
    }
  }

  setActive() {
    console.log("debug active");
    this.state = "active";
  }

  resetActivityTimer() {
    clearTimeout(this.userActivityTimeout);
    this.userActivityTimeout = setTimeout(
      () => this.setInactive(),
      UserActivityHelper.USER_INACTIVITY_TIMEOUT_SECONDS * 1000
    );
  }

  dispose() {
    clearInterval(this.tick);
    clearInterval(this.activityLoggingInterval);
    clearTimeout(this.userActivityTimeout);
  }
}

const userActivityHelper = new UserActivityHelper();
export { getBackendApiURL, userActivityHelper };
